




















































































export default {
  name : 'MedicalDentalProgram',
}
